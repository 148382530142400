import axios from "axios";

function ax() {
  return axios;
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const genericPut = async (
  language,
  ep,
  data,
  ctx = "booking-web",
  contentType = "application/json",
  timeout = 10000
) => {
  return genericPutPost(language, ep, data, ctx, "PUT", contentType, timeout);
};

export const genericPost = async (
  language,
  ep,
  data,
  ctx = "booking-web",
  contentType = "application/json",
  timeout = 30000
) => {
  return genericPutPost(language, ep, data, ctx, "POST", contentType, timeout);
};

const genericPutPost = async (language, ep, data, ctx, method, contentType, timeout) => {
  try {
    let response = undefined;
    if (method === "PUT") {
      response = await ax().put(`/${ctx}/${language}/${ep}`, data, {
        withCredentials: true,
        timeout: timeout,
        headers: { "Content-Type": contentType, "JWT": getCookie("bookkaa-jwt") }
      });
    } else {
      response = await ax().post(`/${ctx}/${language}/${ep}`, data, {
        withCredentials: true,
        timeout: timeout,
        headers: { "Content-Type": contentType }
      });
    }
    if (response.status !== 200 && response.status !== 201) {
      if (response.status !== 204) {
        console.log("Failed with status: " + response.status);
      }
      return {
        requestData: data,
        errorStatus: response.status,
        errorMessage: response.data ? response.data.errorMessage : undefined
      };
    }

    return response.data;
  } catch (ex) {
    console.log("Failed: " + JSON.stringify(ex));
    if (ex.response) {
      let ret = {
        requestData: data,
        errorStatus: ex.response.status
      };
      if (ex.response.data && ex.response.data.errorMessage) {
        ret.errorMessage = ex.response.data.errorMessage;
      } else {
        ret.errorMessage = "Virhe";
      }
      return ret;
    }
  }
  return {
    requestData: data,
    errorStatus: 666,
    errorMessage: "Yhteysvirhe"
  };
};

export const genericGet = async (
  language,
  ep,
  ctx = "booking-web",
  timeout = 30000
) => {
  try {
    let response = await ax().get(`/${ctx}/${language}/${ep}`, {
      withCredentials: true,
      timeout: timeout
    });
    if (response.status !== 200) {
      console.log("Failed with status: " + response.status);
      return {
        errorStatus: response.status,
        errorMessage: response.data ? response.data.errorMessage : undefined
      };
    }

    return response.data;
  } catch (ex) {
    console.log("Failed: " + JSON.stringify(ex));
    if (ex.response) {
      let ret = {
        errorStatus: ex.response.status
      };
      if (ex.response.data && ex.response.data.errorMessage) {
        ret.errorMessage = ex.response.data.errorMessage;
      }
      return ret;
    }
  }
  return {
    errorStatus: 666
  };
};

export const genericDelete = async (language, ep, ctx = "booking-web") => {
  try {
    let response = await ax().delete(`/${ctx}/${language}/${ep}`, {
      withCredentials: true,
      timeout: 10000,
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (response.status !== 200 && response.status !== 204) {
      console.log("Failed with status: " + response.status);
      return {
        errorStatus: response.status,
        errorMessage: response.data ? response.data.errorMessage : undefined
      };
    }

    return response.data;
  } catch (ex) {
    console.log("Failed: " + JSON.stringify(ex));
    if (ex.response) {
      let ret = {
        errorStatus: ex.response.status
      };
      if (ex.response.data && ex.response.data.errorMessage) {
        ret.errorMessage = ex.response.data.errorMessage;
      }
      return ret;
    }
  }
  return {
    errorStatus: 666
  };
};

export default ax;
