import ax from "./axiosApi";
import { genericPost, genericGet, genericDelete } from "./axiosApi";

/**
 * Populates areaOptions for admin reservations.
 *
 * @param {string} language
 */
const areaOptions = language =>
  genericGet(language, "adminReservations/areas", "booking-web").then(
    response => {
      if (response.status !== 200) {
        console.log("Failed with status: " + response.status);
        return {
          errorStatus: response.status
        };
      }
      return response.data;
    }
  );

/**
 * Returns array of products with reservation status set.
 *
 * @param {string} language
 * @param {number} areaId - area where to check reservations
 * @param {number} subareaId - OPTIONAL subarea (pier) id
 * @param {number} productType - SEASON_PLACE for now
 */
const checkReservationStatuses = async (
  language,
  areaId,
  subareaId,
  productType = "SEASON_PLACE"
) => {
  try {
    let response = await ax().post(
      `/booking-web/${language}/a${areaId}/reserve/products`,
      {
        subareaId: subareaId,
        type: productType
      },
      {
        withCredentials: true
      }
    );

    if (response.status !== 200) {
      console.log("Failed with status: " + response.status);
      return {
        errorStatus: response.status
      };
    }

    return response.data;
  } catch (ex) {
    console.log("Failed: " + JSON.stringify(ex));
    if (ex.response && ex.response.data && ex.response.data.errorMessage) {
      return {
        errorStatus: ex.response.status,
        errorMessage: ex.response.data.errorMessage
      };
    }
  }
  return {};
};

/**
 * Returns array of products with reservation status set.
 *
 * @param {string} language
 * @param {number} areaId - area where to check reservations / id of place if ONE_PLACE
 * @param {number} subareaId - OPTIONAL subarea (pier) id
 * @param {number} productType - ONE_PLACE, PUBLIC_SEASON, SEASON_PLACE, GUEST_PLACE or BOAT_PARKING for now
 * @param {date} from - OPTIONAL from (only date part, as UTC) to calculate reservations
 * @param {date} until - OPTIONAL until (only date part, as UTC) to calculate reservations
 */
const getImageMap = async (
  language,
  areaId,
  subareaId,
  productType,
  from,
  until
) => {
  try {
    let response = undefined;
    const qpadmin = productType == "GUEST_ADMIN" ? "admin=true" : "admin=false";
    if (productType === "GUEST_ADMIN") {
      productType = "GUEST_PLACE";
    }
    const qp =
      from && until ? `?from=${from}&until=${until}&${qpadmin}` : `?${qpadmin}`;
    if (productType === "BOAT_PARKING") {
      response = await ax().get(
        `/booking-web/${language}/parkingAdmin/${areaId}_parking.map${qp}`,
        {
          withCredentials: true
        }
      );
    } else if (productType === "SEASON_PLACE") {
      response = await ax().get(
        `/booking-web/${language}/images/${areaId}_season.map${qp}`,
        {
          withCredentials: true
        }
      );
    } else if (productType === "PUBLIC_SEASON") {
      response = await ax().get(
        `/booking-web/${language}/images/public/${areaId}_season.map`,
        {
          withCredentials: false
        }
      );
    } else if (productType === "GUEST_PLACE") {
      response = await ax().get(
        `/booking-web/${language}/images/${areaId}_guest.map${qp}`,
        {
          withCredentials: true
        }
      );
    } else if (productType === "ONE_PLACE") {
      response = await ax().get(
        `/booking-web/${language}/images/place/${areaId}.map`,
        {
          withCredentials: true
        }
      );
    }

    if (response.status !== 200) {
      console.log("Failed with status: " + response.status);
      return {
        errorStatus: response.status
      };
    }

    return response.data;
  } catch (ex) {
    console.log("Failed: " + JSON.stringify(ex));
    if (ex.response && ex.response.data && ex.response.data.errorMessage) {
      return {
        errorStatus: ex.response.status,
        errorMessage: ex.response.data.errorMessage
      };
    }
  }
  return {};
};

/**
 *
 * @param {string} language
 * @param {number} areaId
 * @param {number} productId - as returned by the server for given product
 * @param {string} seasonId - OPTIONAL: by default the active season (if one exists; several ongoing seasons not supported yet)
 */
const reserveSeasonPlace = async (language, areaId, productId, seasonId) => {
  try {
    let response = await ax().post(
      `/booking-web/${language}/a${areaId}/reserve/reserveSeasonPlace`,
      {
        productId: productId,
        seasonId: seasonId
      },
      {
        withCredentials: true
      }
    );

    if (response.status !== 200) {
      console.log("Failed with status: " + response.status);
    } else {
      console.log("Successfully reserved place");
    }

    return response.data;
  } catch (ex) {
    console.log("Failed: " + JSON.stringify(ex));
    if (ex.response && ex.response.data && ex.response.data.errorMessage) {
      return {
        errorStatus: ex.response.status,
        errorMessage: ex.response.data.errorMessage
      };
    }
  }
  return { errorMessage: "Odottamaton virhe" };
};

const pay = async (language, cartId, additional, customer) => {
  return genericPost(language, `payment/pay/${cartId}`, {
    additionalProducts: additional,
    customer: customer
  });
};

const adminReservationCalendar = async (areaId, language, start, end) => {
  return genericGet(
    language,
    `parkingAdmin/reservationCalendar/${areaId}?start=${
      start ? start : ""
    }&until=${end ? end : ""}`
  );
};

const guestReservationCalendar = async (area, language, data) => {
  return genericPost(language, `a${area}/boatparking/alternatives`, data);
};

const adminParkingList = async (language, areaId, data) => {
  return genericGet(
    language,
    `parkingAdmin/${areaId}_parking.list?from=${data.from.format(
      "YYYY-MM-DD"
    )}&until=${data.until.format("YYYY-MM-DD")}`
  );
};

const guestReservationReport = async (areaId, language, data, sellerId) => {
  console.log("Getting reservation report");
  if (!areaId) {
    areaId = "0";
  }
  return genericGet(
    language,
    `parkingAdmin/${areaId}_reservation.list?from=${data.from}&until=${
      data.until
    }${sellerId ? "&sellerId" + sellerId : ""}`,
    "booking-web",
    180000 // 3 minutes timeout
  );
};

const fetchProductDetails = async (areaId, language, placeId, from, until) => {
  let qp = `?area=${areaId}`;
  if (from && until) {
    qp += `?${from.format("YYYY-MM-DD")}&until=${until.format("YYYY-MM-DD")}`;
  }
  return genericGet(language, `parkingAdmin/place/${placeId}/details${qp}`);
};

const storeProductDetails = async (language, data, from, until) => {
  let qp = "";
  if (from && until) {
    qp = `?${from.format("YYYY-MM-DD")}&until=${until.format("YYYY-MM-DD")}`;
  }
  return genericPost(language, `parkingAdmin/place${qp}`, data);
};

const getAvailableProducts = async (areaId, language, data) => {
  return genericPost(language, `a${areaId}/reserve/freeProducts`, data);
};

const guardPatrolReserve = async (areaId, language, data) => {
  return genericPost(language, `a${areaId}/reserve/reservePatrol`, data);
};

const cancelReservation = async (areaId, language, data) => {
  return genericPost(language, `a${areaId}/reserve/cancel`, data);
};

const cancelReservationById = async (areaId, language, id, day) => {
  if (day) {
    return genericDelete(language, `parkingAdmin/reservation/${id}?day=${day}`);
  }
  return genericDelete(language, `parkingAdmin/reservation/${id}`);
};

const markReservationPaidById = async (
  areaId,
  language,
  id,
  paymentStatus = "MARKED_PAID"
) => {
  return genericPost(language, `parkingAdmin/reservation/${id}/status`, {
    paymentStatus: paymentStatus
  });
};

const checkOrder = async (language, orderNumber) => {
  try {
    let response = await ax().get(
      `/booking-web/${language}/payment/check/${orderNumber}`,
      {
        withCredentials: true
      }
    );

    if (response.status !== 200) {
      console.log("Failed with status: " + response.status);
      return {
        errorStatus: response.status
      };
    }

    return response.data;
  } catch (ex) {
    console.log("Failed: " + JSON.stringify(ex));
    if (ex.response) {
      let ret = {
        errorStatus: ex.response.status
      };
      if (ex.response.data && ex.response.data.errorMessage) {
        ret.errorMessage = ex.response.data.errorMessage;
      }
      return ret;
    }
  }
  return { errorStatus: 666 };
};

const retrieveOrder = async (language, orderNumber) => {
  try {
    let response = await ax().get(
      `/booking-web/${language}/reserve/retrieveOrder/${orderNumber}`,
      {
        withCredentials: true
      }
    );

    if (response.status !== 200) {
      console.log("Failed with status: " + response.status);
      return {
        errorStatus: response.status,
        errorMessage: response.data ? response.data.errorMessage : undefined
      };
    }

    return response.data;
  } catch (ex) {
    console.log("Failed: " + JSON.stringify(ex));
    if (ex.response) {
      let ret = {
        errorStatus: ex.response.status
      };
      if (ex.response.data && ex.response.data.errorMessage) {
        ret.errorMessage = ex.response.data.errorMessage;
      }
      return ret;
    }
  }
  return { errorStatus: 666 };
};

const updateReservation = async (area, language, data) => {
  return genericPost(language, `a${area}/parkingAdmin/reservation`, data);
};

const boatParkAdmin = async (area, language, data) => {
  return genericPost(language, `a${area}/parkingAdmin/reserve`, data);
};
const boatParkWizard = async (area, language, data) => {
  if (area) {
    return genericPost(language, `a${area}/boatparking/wiz`, data);
  } else {
    return genericPost(language, `boatparking/wiz`, data);
  }
};
const searchCustomer = async (area, language, search) => {
  return genericPost(language, `a${area}/customers/search`, search);
};
const registerByReservation = async (area, language, data) => {
  return genericPost(language, `a${area}/reserve/register`, data);
};
// TODO: area
const reserveForCustomer = async (language, data) => {
  return genericPost(language, "customers/reserve", data);
};
const getLogEntries = async (area, language, data) => {
  return genericGet(language, `a${area}/log/latest/${data}`);
};
const addLogEntry = async (area, language, data) => {
  return genericPost(language, `a${area}/log/${data.pwd}`, data);
};

export default {
  areaOptions,
  checkReservationStatuses,
  reserveSeasonPlace,
  getImageMap,
  pay,
  checkOrder,
  retrieveOrder,
  searchCustomer,
  reserveForCustomer,
  registerByReservation,
  getAvailableProducts,
  guardPatrolReserve,
  cancelReservation,
  cancelReservationById,
  markReservationPaidById,
  boatParkWizard,
  guestReservationCalendar,
  boatParkAdmin,
  guestReservationReport,
  updateReservation,
  adminParkingList,
  adminReservationCalendar,
  getLogEntries,
  addLogEntry,
  fetchProductDetails,
  storeProductDetails
};
