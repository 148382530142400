import { Map } from "immutable";
import React from "react";
import { CircleLoader } from "react-spinners";
import Customer from "../components/boatparking/Customer";
import BkContentArea from "../components/layout/BkContentArea";
import BkModifyButton from "../components/layout/BkModifyButton";
import { genericGet, genericPost } from "../services/axiosApi";

class SeasonQueueForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: Map(),
      saveDisabled: true,
      saving: false,
      saved: false,
      areas: []
    };
    props.getTranslations("booking-web", "fi", "reserve");
    this.renderAccount = this.renderAccount.bind(this);
    this.save = this.save.bind(this);
    this.updateCustomer = this.updateCustomer.bind(this);
  }
  save() {
    genericPost("fi", "queue", this.state.customer).then(r => {
      if (!r.errorStatus) {
        this.setState({ saving: false, saved: true });
        if (this.props.close) {
          this.props.close();
        }
      } else {
        this.setState({
          saving: false,
          errorMessage: r.errorMessage
            ? r.errorMessage
            : "Tallennuksessa tapahtui virhe. Tarkista arvot ja yritä uudestaan."
        });
      }
    });
  }
  render() {
    const l = this.props.location; // shortcut
    const t = key => l.translate(l, key);

    if (t("close-button") === "close-button" || this.state.areas.length === 0) {
      setTimeout(() => {
        if (t("close-button") === "close-button") {
          this.props.getTranslations("booking-web", l.language, "reserve");
        }
        if (!this.state.areas || this.state.areas.length === 0) {
          genericGet(l.language, l.area + "/queue/areas").then(ret => {
            if (Array.isArray(ret)) {
              this.setState({ areas: ret });
            }
          });
        }
      }, 2);
      return <CircleLoader />;
    }

    return this.renderAccount(t);
  }

  renderAccount(t) {
    return (
      <BkContentArea>
        <center style={{ marginRight: "50px" }}>
          <div className="bkProfile-leftColumn">
            <div className="togglecontainer">
              <section>
                <div className="single_toggle">
                  <div className="bk-special-heading">
                    <img
                      src="/booking-web/fi/queue/logo.png"
                      alt=""
                      style={{ width: "150px" }}
                    />
                    <h3 className="column-title">Asiakkaan ja veneen tiedot</h3>
                  </div>
                  {this.state.saved && (
                    <div className="bk_okMessage">
                      Tiedot tallennettu onnistuneesti.
                    </div>
                  )}
                  {!this.state.saved && (
                    <React.Fragment>
                      {this.state.errorMessage && (
                        <div className="bk_errorMessage display">
                          {this.state.errorMessage}
                        </div>
                      )}
                      <br style={{ clear: "both" }} />
                      <div className="bk_placeDetails">
                        <p style={{ textAlign: "left" }}></p>
                      </div>
                      <Customer
                        t={t}
                        autocomplete={true}
                        updateCustomer={(field, value) =>
                          this.updateCustomer(field, value)
                        }
                        stateCustomer={this.state.customer}
                        renderAsTable={true}
                        showAddress={true}
                        requireAddress={true}
                        repeatEmail={true}
                        askBoatSize={true}
                        boatRegister={true}
                        boatOptional={false}
                        {...this.props}
                      />

                      <div className="bk_placeDetails">
                        <center>
                          <label>*Satama</label>
                        </center>
                        <center>
                          <select
                            onInput={e =>
                              this.updateCustomer("areaId", e.target.value)
                            }
                            onChange={e =>
                              this.updateCustomer("areaId", e.target.value)
                            }
                          >
                            <option value="">- valitse -</option>
                            {this.state.areas.map(a => (
                              <option value={a.id}>
                                {a.name} ({a.code})
                              </option>
                            ))}
                          </select>
                        </center>
                      </div>
                      <br />
                      <BkModifyButton
                        disabled={this.state.saveDisabled}
                        busy={this.state.saving}
                        buttonTitle={t("send")}
                        callback={this.save}
                      />
                      {this.props.close && (
                        <BkModifyButton
                          buttonTitle={t("close-button")}
                          callback={this.props.close}
                        />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </section>
            </div>
          </div>
        </center>
      </BkContentArea>
    );
  }
  updateCustomer(fieldName, value) {
    const c = this.state.customer.set(fieldName, value);
    this.setState({
      customer: c,
      saveDisabled:
        this.requiredDataMissing(c) || c.get("email") !== c.get("repeatEmail")
    });
  }
  requiredDataMissing(c) {
    console.log(JSON.stringify(c));
    return (
      !c.get("areaId") ||
      !c.get("firstName") ||
      !c.get("lastName") ||
      !c.get("mobile") ||
      !c.get("email") ||
      !c.get("streetAddress") ||
      !c.get("town") ||
      !c.get("postOffice") ||
      !c.get("boatLength") ||
      !c.get("boatWidth") ||
      !c.get("boatDepth")
    );
  }
}

export default SeasonQueueForm;
