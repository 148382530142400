import React from "react";
import BookkaaHeader from "../BookkaaHeader";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import cloneDeep from "lodash/cloneDeep";
import BkModifyButton, { getButtons } from "../layout/BkModifyButton";
import { genericGet, genericPost, genericPut } from "../../services/axiosApi";
import moment from "moment";
import { CircleLoader } from "react-spinners";
import { PlaceDetailsRenderer } from "./PlaceDetailsRenderer";
import { SearchListControls } from "../layout/SearchListControls";
import swal from "@sweetalert/with-react";
import { shallowEqual } from "recompose";
import { Map } from "immutable";
import { EnumSelect } from "../EnumSelect";
import { AreaSelect } from "../AreaSelect";
import reservationService from "../../services/reservation";
import { isString } from "pdfmake/src/helpers";
import { ensureLoggedIn } from "../../ducks/login";
import { MassUpdateDiff } from "./SeasonMassUpdate";
import OkErrorMessage from "../layout/OkErrorMessage";

class SeasonCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.setPaid = this.setPaid.bind(this);
    this.setToBeInvoiced = this.setToBeInvoiced.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
    this.sendPaymentLink = this.sendPaymentLink.bind(this);
    this.setToReserved = this.setToReserved.bind(this);
    this.transition = this.transition.bind(this);
    this.setFree = this.setFree.bind(this);
    this.moveCustomer = this.moveCustomer.bind(this);
    this.openMoveCustomer = this.openMoveCustomer.bind(this);
    this.dialogClosed = this.dialogClosed.bind(this);
    this.selectCallback = this.selectCallback.bind(this);
    this.openCallback = this.openCallback.bind(this);
    this.loadPlaces = this.loadPlaces.bind(this);
    this.getPreviousNext = this.getPreviousNext.bind(this);
    this.buttonDisabledCallback = this.buttonDisabledCallback.bind(this);
    this.resendConfirmation = this.resendConfirmation.bind(this);
    this.customerSearchQueryString = this.customerSearchQueryString.bind(this);
    this.sendExport = this.sendExport.bind(this);
    this.acceptUpdate = this.acceptUpdate.bind(this);

    this.state = {
      sellerId: undefined,
      areaId: props.location.area,
      busy: false,
      first: 0,
      last: undefined,
      totalCount: 0,
      pageSize: 100,
      data: this.props.openedData,
      placeDetails: this.props.placeDetails,
      selectedCustomer: undefined,
      places: [],
      loadingPlaces: "INITIAL",
      search: "",
      selectedItems: [],
      availableTemplates: [],
      customerToMove: undefined,
      filter: Map({ placeForSale: "ALL" }),
      importData: undefined
    };
    const l = props.location;
    props.getTranslations(l.ctx, l.language, l.endpoint);
    if (!this.props.areas) {
      this.props.getAreas(l.language);
    }
    this.timer = null;
    this.loadPlaces(0);
  }

  isUpdatingStatus(status) {
    return (
      status === "SEND_PAYMENT_LINK" ||
      status === "TO_BE_INVOICED" ||
      status === "PAYMENT_PENDING"
    );
  }

  componentWillUnmount() {
    clearTimeout(this.statusTimer);
    this.statusTimer = undefined;
  }

  componentDidUpdate(prevProps) {
    if (this.props.areas && !this.state.sellerId && this.props.location.area) {
      this.props.areas.forEach(a => {
        // eslint-disable-next-line eqeqeq
        if (a.id == this.props.location.area) {
          this.setState({ sellerId: a.seller });
        }
      });
    }
    if (this.props.openedData) {
      if (
        (!shallowEqual(this.props.openedData, this.state.data) && !!shallowEqual(this.props.openedData, prevProps.data)) ||
        (!shallowEqual(this.props.placeDetails, this.state.placeDetails) && !shallowEqual(this.props.placeDetails, prevProps.placeDetails))
      ) {
        console.log("updating state.placeDetails from props");
        this.setState({
          data: this.props.openedData,
          placeDetails: this.props.placeDetails
        });
      }
    } else if (this.props.location.file) {
      if (!this.state.placeDetails || this.state.placeDetails.placeId != this.props.location.file) {
        this.fetchDetails(this.props.location.file);
      }
    }
  }
  componentDidMount() {
    const l = this.props.location;
    return genericGet(l.language, `a${l.area}/sellers/current`).then(s => {
      if (s.code) {
        return genericGet(l.language, `areas/${l.area}`).then(a => {
          return genericGet(l.language, `msgs/${s.code}/${a.code}`, "messagecenter-web", 15000).then(r => {
            const tmpls = [];
            r.forEach( t => {
              if (t.id) {
                tmpls.push(t.type);
              }
            });
            this.setState({ availableTemplates: tmpls });
          });
        });
      } 
    });
  }
  dialogClosed() {}

  fetchDetails(placeId) {
    return reservationService
      .fetchProductDetails(
        this.state.areaId,
        this.props.location.language,
        placeId
      )
      .then(ret => {
        this.setState({ placeDetails: ret, data: ret, errorMessage: ret.errorMessage });
        if (
          !this.state.placeEditMode &&
          !this.statusTimer &&
          // eslint-disable-next-line prettier/prettier
          this.isUpdatingStatus(ret && ret.holder && ret.holder.reservationStatus)
        ) {
          this.statusTimer = setTimeout(() => this.refreshStatus(ret), 2000);
        } else {
          clearTimeout(this.statusTimer);
          this.statusTimer = undefined;
        }
      });
  }

  refreshStatus(details) {
    const l = this.props.location; // shortcut
    // eslint-disable-next-line prettier/prettier
    genericGet(l.language, `parkingAdmin/place/${details.placeId}/status`, "booking-web", 1000).then(r => {
      if (r && r.status !== details.holder.reservationStatus) {
        this.fetchDetails(details.placeId);
      } else {
        this.statusTimer = setTimeout(() => this.refreshStatus(details), 5000);
      }
    });
  }

  sendExport(data) {
    const l = this.props.location;
    const t = key => l.translate(l, key);
    const q = this.customerSearchQueryString(moment(), false);
    const EXCEL_IMPORT = {
      title: t("customer-import-button"),
      fileUpload: true,
      fileExtensions: "xlsx",
      callback: (data) => this.setState({ importFile: data })
    }
    const importButton = getButtons([ EXCEL_IMPORT ], {})[0];
    const exportUrl = `/booking-web/${l.language}/seasonAdmin/places/customerexport.xlsx${q}`;

    swal({
      title: t("massUpdate.title"),
      text: t("massUpdate.text"),
      buttons: {
        cancel: t("cancel-button"),
        ok: "Ok"
      },
      content: (
        <div style={{ overflow: "scroll", textDirection: "left" }}>
          <p>Päivitettävän taulukon aktiivisilla hakuehdoilla voi ladata täältä: <a href={exportUrl}>export.xlsx</a></p>

          <hr />
          {importButton}
        </div>
      )
    }).then(ok => {
      if (ok && this.state.importFile) {
        const data = this.state.importFile;
        const formData = new FormData();
        formData.append("customerexport", data, data.name);
        return genericPost(
          l.language,
          `seasonAdmin/places/customerimport.xlsx${q}`,
          formData,
          "booking-web",
          "multipart/form-data").then(res => 
            this.setState({ importData: res.data ? res : undefined, errorMessage: res.errorMessage, okMessage: res.okMessage })
          );
      }
    });

  };

  openSendBs(data) {
    const l = this.props.location;
    const t = key => l.translate(l, key);
    console.log(data);
    const formData = new FormData();
    formData.append("bankstatement", data, data.name);
    genericPost(
      l.language,
      `a${l.area}/adminInvoice/bankStatement`,
      formData,
      "booking-web",
      "multipart/form-data"
    ).then(r => {
      swal({
        title: t("sendBs.alertTitle"),
        text: t("sendBs.alertText"),
        icon: "info",
        className: "swal-wide",
        buttons: {
          cancel: t("cancel-button"),
          ok: "Ok"
        },
        content: (
          <div style={{ overflow: "scroll", textDirection: "left" }}>
            <table>
              <tr>
                <th colSpan="3" className="header">Täsmäytetyt</th>
              </tr>
              <tr>
                <th>Kirjauspäivä</th><th>Viite</th><th>Summa</th>
              </tr>
              {r.test.matchedPayments.map(t => {
                  const elements = [];
                  elements.push(t.newPayments.map(p =>
                  <tr title={p.paymentInfo}>
                    <td>{p.bookingDate}</td>
                    <td>{p.reference}</td>
                    <td style={{ color: p.fullyPaid ? "green" : "red"}}>{p.payment}</td>
                  </tr>));
                  elements.push(t.previousPayments.map(p =>
                    <tr>
                      <td>{p.registrationDate}</td>
                      <td>{p.reference}</td>
                      <td>{p.amount} aiemmin käsitelty</td>
                    </tr>));
                    return elements;
                  })}
              {r.test.unmatched && 
                <tr>
                  <th colSpan="3" className="header">Ei täsmäytetty:</th>
                </tr>
              }
              {r.test.unmatched.map(line => 
                <tr>
                  <td colSpan="3" style={{ textAlign: "left" }}>{line}</td>
                </tr>
              )}
            </table>
          </div>
        )
      }).then(ok => {
        if (ok) {
          genericPost(l.language, `a${l.area}/adminInvoice/bankStatement/${r.reference}`, {}, "booking-web").then(response => {
                this.setState({ errorMessage: response.errorMessage, okMessage: response.okMessage, uploadBs: false              
              });
          })
        }
      });
    });
  }

  openCallback(item) {
    return this.fetchDetails(item.placeId);
  }
  selectCallback(item, add) {
    let items = [];
    if (Array.isArray(item)) {
      items = item;
    } else if (item && add) {
      if (this.state.selectedItems) {
        let skip = false;
        this.state.selectedItems.forEach(i =>
          i.placeId === item.placeId ? (skip = true) : items.push(i)
        );
        if (!skip) {
          items.push(item);
        }
      } else {
        items.push(item);
      }
    } else if (item) {
      items.push(item);
    }
    this.setState({ selectedItems: items });
  }
  customerSearchQueryString(ref, paginate, from, sizeOptional) {
    const seller = this.state.sellerId ? this.state.sellerId : "";
    const area = this.state.areaId ? this.state.areaId : "";
    const size = sizeOptional ? sizeOptional : this.state.pageSize;
    const f = this.state.filter;
    const fsale = `&placeForSale=${f.get("placeForSale")}`;
    const filters =
      (f.get("status") ? `&status=${f.get("status")}` : "") +
      (f.get("length") ? `&length=${f.get("length")}` : "") +
      (f.get("width") ? `&width=${f.get("width")}` : "") +
      (f.get("depth") ? `&depth=${f.get("depth")}` : "") +
      (f.get("newCustomer") ? "&newCustomer=true" : "") +
      (f.get("placeForSale") !== "ALL" ? fsale : "") +
      (f.get("invoiceable") ? "&invoiceable=true" : "");
    const q = `?seller=${seller}&area=${area}&reference=${ref}&text=${this.state.search}${filters}`;
    if (paginate) {
      return q + `&first=${from}&rows=${size}`;
    }
    return q;
  }
  loadPlaces(from, sizeOptional) {
    clearTimeout(this.timer);
    const ref = moment().unix().toString();
    const q = this.customerSearchQueryString(ref, true, from, sizeOptional);
    this.setState({ loadingPlaces: ref });
    return genericGet(
      this.props.location.language,
      "seasonAdmin/places" + q,
      "booking-web",
      15000
    ).then(res => {
      if (this.state.loadingPlaces === "INITIAL" || (res.reference && this.state.loadingPlaces === res.reference)) {
        return new Promise(resolve =>
          this.setState(
            {
              places: res.items,
              totalCount: res.totalCount,
              first: res.first,
              last: res.last,
              pageSize: res.pageSize,
              loadingPlaces: undefined
            },
            () => resolve("ok")
          )
        );
      } else {
        console.log(`Reference in state: ${this.state.loadingPlaces} doesn't match response: ${res.reference}`);
      }
    });
  }
  setPaid(item) {
    return this.transition(item, "MARKED_PAID");
  }
  openMoveCustomer(item) {
    const ref = moment();
    const seller = this.state.sellerId ? this.state.sellerId : "";
    const area = this.state.areaId ? this.state.areaId : "";
    const q = `?seller=${seller}&area=${area}&first=0&rows=10000&reference=${ref}&text=&minimal=true`;
    return genericGet(
      this.props.location.language,
      "seasonAdmin/places" + q,
      "booking-web",
      15000
    ).then(res => {
      if (res.reference) {
        const c = Array.isArray(item) ? item[0] : item;
        const data = {
          customerId: c.holder.customerId,
          placeId: c.placeId
        };
        return new Promise(resolve =>
          this.setState(
            {
              customerToMove: data,
              moveTargetPlaceId: undefined,
              allPlaces: res.items
            },
            resolve
          )
        );
      }
    });
  }
  moveCustomer() {
    return genericPost(
      this.props.location.language,
      `/seasonAdmin/product/${this.state.moveTargetPlaceId}/move`,
      this.state.customerToMove
    ).then(c => {
      if (c.errorMessage) {
        this.setState({ errorMessage: c.errorMessage });
        console.log("Setting place details error message: " + c.errorMessage);
        return Promise.resolve();
      }
      return this.loadPlaces(this.state.first).then(
        () =>
          new Promise(resolve =>
            this.setState(
              {
                data: undefined,
                moveTargetPlaceId: undefined,
                customerToMove: undefined,
                placeDetailsError: undefined
              },
              resolve
            )
          )
      );
    });
  }
  resendConfirmation(itemParam) {
    const l = this.props.location;
    let item = {};
    if (Array.isArray(itemParam) && itemParam.length > 0) {
      item = itemParam[0];
    } else {
      item = itemParam;
    }
    return genericPost(
      l.language,
      `/seasonAdmin/place/${item.placeId}/confirmation`,
      {}
    ).then(ret =>
      this.setState({
        errorMessage: ret.errorMessage,
        okMessage: ret.okMessage
      })
    );
  }
  setFree(item) {
    const location = this.props.location;
    const t = key => location.translate(location, key);

    return swal({
      title: t("setFree.alertTitle"),
      text: t("setFree.alertText"),
      icon: "warning",
      buttons: [t("cancel-button"), "Ok"],
      dangerMode: true
    }).then(willDo => {
      if (willDo) {
        if (Array.isArray(item)) {
          item.forEach(i => {
            i.customerId = undefined;
            if (i.holder) {
              i.holder.customerId = undefined;
            }
          });
        } else {
          item.customerId = undefined;
          if (item.holder) {
            item.holder.customerId = undefined;
          }
        }
        return this.transition(item, "FREE");
      } else {
        console.log("Cancelled");
      }
    });
  }
  setToBeInvoiced(item) {
    const linkType = "TO_BE_INVOICED";
    const location = this.props.location;
    const t = key => location.translate(location, key);
    const reminder =  linkType !== "TO_BE_INVOICED";
    return swal({
      title: reminder ? t("sendInvoiceReminder.alertTitle") : t("sendInvoice.alertTitle"),
      text: reminder ? t("sendInvoiceReminder.alertText") : t("sendInvoice.alertText"),
      icon: "info",
      content: {
        element: "input",
        attributes: {
          placeholder: t("sendLink.dueDatePlaceholder"),
          type: "text"
        }
      },
      buttons: [
        {
          text: t("cancel-button"),
          value: "CANCEL",
          visible: true,
          closeModal: true
        },
        "Ok"
      ],
      dangerMode: true
    }).then(willDo => {
      console.log("returned: " + willDo);
      if (willDo !== "CANCEL") {
        if (Array.isArray(item) && willDo) {
          item.forEach(i => (i.dueDateDays = willDo));
        }
        return this.transition(item, linkType);
      }
    });
  }
  downloadInvoice(item) {
    const linkType = "TO_BE_INVOICED";
    const location = this.props.location;
    const t = key => location.translate(location, key);
    if (!Array.isArray(item) || !item[0] || !item[0].holder) {
      return;
    }
    ensureLoggedIn(this.props.jwt, "messagecenter-web", location.language, "invoice");
    const alreadyInvoiced = (item[0].holder.reservationStatus === "INVOICED" || item[0].holder.reservationStatus === "TO_BE_INVOICED");
    const text = alreadyInvoiced ? t("downloadInvoice.alertText.alreadyInvoiced") : t("downloadInvoice.alertText");
    return swal({
      title: t("downloadInvoice.alertTitle"),
      text:  text,
      icon: "info",
      content: alreadyInvoiced ? "" : {
        element: "input",
        attributes: {
          placeholder: t("sendLink.dueDatePlaceholder"),
          type: "text"
        }
      },
      buttons: [
        {
          text: t("cancel-button"),
          value: "CANCEL",
          visible: true,
          closeModal: true
        },
        "Ok"
      ],
      dangerMode: true
    }).then(willDo => {
      console.log("returned: " + willDo);
      if (willDo !== "CANCEL") {
        if (alreadyInvoiced) {
          genericGet(location.language, `seasonAdmin/place/${item[0].placeId}/invoice`).then(link => {
            if (link.errorMessage) {
              this.setState({errorMessage: link.errorMessage});
            } else {
              console.log("Download invoice link: " + link);
              window.open(link)
            }
          });
        } else {
          item.forEach(i => {i.dueDateDays = willDo; i.invoiceSendType='DOWNLOAD'});
          return this.transition(item, linkType).then((ref) => {
            console.log("Download invoice ref: " + ref);
            genericGet(location.language, `seasonAdmin/place/${item[0].placeId}/invoice`).then(link => {
              if (link.errorMessage) {
                this.setState({errorMessage: link.errorMessage});
              } else {
                console.log("Download invoice link: " + link);
                if (link && isString(link)) {
                  window.open(link);
                }
              }
            });
          });
        }
      }
    });
  }
  setToReserved(item) {
    return this.transition(item, "IN_CART");
  }
  sendPaymentLink(item, linkType) {
    const location = this.props.location;
    const t = key => location.translate(location, key);
    const reminder =  linkType === "SEND_PAYMENT_REMINDER";
    swal({
      title: reminder ? t("sendReminder.alertTitle") : t("sendLink.alertTitle"),
      text: reminder ? t("sendReminder.alertText") : t("sendLink.alertText"),
      icon: "info",
      content: {
        element: "input",
        attributes: {
          placeholder: t("sendLink.dueDatePlaceholder"),
          type: "text"
        }
      },
      buttons: [
        {
          text: t("cancel-button"),
          value: "CANCEL",
          visible: true,
          closeModal: true
        },
        "Ok"
      ],
      dangerMode: true
    }).then(willDo => {
      console.log("returned: " + willDo);
      if (willDo !== "CANCEL") {
        if (Array.isArray(item) && willDo) {
          item.forEach(i => (i.dueDateDays = willDo));
        }
        return this.transition(item, linkType);
      }
    });
  }

  transition(itemParam, status) {
    let items = [];
    if (!itemParam) {
      console.log("No item, won't do anything");
      return;
    } else if (Array.isArray(itemParam)) {
      items = itemParam;
      console.log(`Transition: ${items.length} items to ${status}`);
    } else {
      items.push(itemParam);
    }
    const places = [];
    for (let i = 0; i < items.length; i++) {
      console.log(
        "Setting status: " + status + " to item " + JSON.stringify(items[i])
      );
      let cloned = cloneDeep(items[i]);
      cloned.reservationStatus = status;
      places.push(cloned);
    }
    console.log("Posting: " + JSON.stringify(places));
    this.setState({ busy: true });
    let reference = null;
    const promise = genericPost(
      this.props.location.language,
      `/seasonAdmin/places/status`,
      places
    ).then((ref) => {
      reference = ref;
      if (!this.state.data) {
        return this.loadPlaces(this.state.first);
      } else if (this.state.data.placeId) {
        return new Promise(resolve =>
          this.setState(
            {
              data: places[0],
              placeEditMode: false
            },
            resolve
          )
        ).then(() => this.fetchDetails(this.state.data.placeId));
      }
    });
    promise.then(
      () => new Promise(resolve => this.setState({ busy: false }, resolve))
    );
    return promise.then(() => reference);
  }
  componentDidCatch(e) {
    console.error("Failed: " + e + ", stack: " + e.stack);
  }

  buttonDisabledCallback(buttonObject, dataObject) {
    if (this.state.busy) {
      return true;
    }
    // check required message template SMS/email
    if ((buttonObject.requiresEmailTemplate || buttonObject.requiresSmsTemplate) && 
        (buttonObject.requiresEmailTemplate && this.state.availableTemplates.indexOf(buttonObject.requiresEmailTemplate) == -1) &&
        (buttonObject.requiresSmsTemplate   && this.state.availableTemplates.indexOf(buttonObject.requiresSmsTemplate) == -1)) {
      console.log("Template not available: " + buttonObject.requiresEmailTemplate + " and " + buttonObject.requiresSmsTemplate);
      return true;
    }

    const btnId = buttonObject.title;
    if (!dataObject || (Array.isArray(dataObject) && dataObject.length === 0)) {
      return true;
    } else if (!buttonObject.whenStatus) {
      return false;
    } else if (dataObject.reservationStatus) {
      if (!dataObject.basePrice) {
        return true;
      }
      if (
        buttonObject.requiresCustomer &&
        !dataObject.customerId &&
        (!dataObject.holder || !dataObject.holder.customerId)
      ) {
        return true;
      }
      // eslint-disable-next-line prettier/prettier
      if (buttonObject.enabledForOldSeason && dataObject.holder && dataObject.holder.oldSeason) {
        return false; // can for example send new payment request to PAID place, if paid for previous season
      }
      return !buttonObject.whenStatus.includes(dataObject.reservationStatus);
    } else if (dataObject.holder && dataObject.holder.reservationStatus) {
      // eslint-disable-next-line prettier/prettier
      if (buttonObject.enabledForOldSeason && dataObject.holder && dataObject.holder.oldSeason) {
        return false; // can for example send new payment request to PAID place, if paid for previous season
      }
      return !buttonObject.whenStatus.includes(
        dataObject.holder.reservationStatus
      );
    } else if (Array.isArray(dataObject)) {
      if (!buttonObject.supportsMulti && dataObject.length > 1) {
        return true; // disable if button doesn't support multiselect
      }
      for (let i = 0; i < dataObject.length; i++) {
        if (
          !buttonObject.whenStatus.includes(dataObject[i].reservationStatus) &&
            (!dataObject[i].holder ||
            !buttonObject.whenStatus.includes(
              dataObject[i].holder.reservationStatus
            ))
            // eslint-disable-next-line prettier/prettier
            && (!buttonObject.enabledForOldSeason || !dataObject[i].holder || !dataObject[i].holder.oldSeason)
            // eslint-disable-next-line prettier/prettier
            && (!buttonObject.enabledForOldSeason || !dataObject[i].oldSeason)
        ) {
          console.debug(
            "Status doesn't match and no matching holder: " +
              btnId +
              " / " +
              JSON.stringify(dataObject[i])
          );
          return true;
        } else if (
          buttonObject.requiresCustomer &&
          !dataObject[i].customerId &&
          (!dataObject[i].holder || !dataObject[i].holder.customerId)
        ) {
          console.debug("Holder required but not present for " + btnId);
          return true;
        } else if (
          buttonObject.requiresInvoicing &&
          !dataObject[i].canInvoice
        ) {
          console.debug("Invoicing not enabled: " + btnId);
          return true;
        }
        if (
          buttonObject.requiresEmailOrMobile &&
          !dataObject[i].email &&
          !dataObject[i].mobile &&
          (!dataObject[i].holder || !dataObject[i].holder.email) &&
          (!dataObject[i].holder || !dataObject[i].holder.mobile)
        ) {
          console.debug("No email/mobile: " + btnId);
          return true;
        }
      }
    }
    return false;
  }

  copyNonNull(data, obj) {
    if (!obj) {
      console.log("Cannot copy values, object null");
      return;
    }
    Object.keys(obj).forEach(function(key) {
      let i = obj[key];
      if (i != null) {
        data[key] = i;
      }
    });
  }

  render() {
    if (
      this.props.openedData &&
      (!this.state.data ||
        !shallowEqual(this.state.data, this.props.openedData))
    ) {
      console.log(
        "Updating state data from props: " +
          JSON.stringify(this.props.openedData)
      );
      this.setState({ data: this.props.openedData });
      return <CircleLoader />;
    }
    const location = this.props.location;
    const t = key => location.translate(location, key);

    const error = this.state.errorMessage ? (
      <div className="bk_errorMessage" style={{ display: "block" }} onClick={() => this.setState({ errorMessage: undefined })}>
        {this.state.errorMessage}
      </div>
    ) : this.state.okMessage ? (
      <div
        className="bk_okMessage"
        style={{ display: "block" }}
        onClick={() => this.setState({ okMessage: undefined })}
      >
        {this.state.okMessage}
      </div>
    ) : (
      undefined
    );
    if (this.state.errorMessage || this.state.okMessage) {
      if (this.messageTimer) {
        clearTimeout(this.messageTimer);
      }
      this.messageTimer = setTimeout(
        () => this.setState({ errorMessage: undefined, okMessage: undefined }),
        15000
      );
    }


    const searchQ = this.customerSearchQueryString(moment(), false);
    const exportUrl = `/booking-web/${location.language}/seasonAdmin/places/customerexport.xlsx${searchQ}`;

    if (this.state.importData) {
      const content = [];
      if (error) {
        content.push(error);
      }
      content.push(<MassUpdateDiff data={this.state.importData.data} invalidDataCb={inv => {
        if (inv != this.state.invalidData) {
          this.setState({ invalidData: inv});
        }
      }}/>);
      content.push(<hr />);
      content.push(
        <BkModifyButton
          buttonTitle={t("accept-places-file")}
          disabled={this.state.invalidData}
          callback={() => this.acceptUpdate()}
        />
      );
      content.push(
        <BkModifyButton
          buttonTitle={t("cancel-button")}
          callback={() => this.setState({ importData: undefined, invalidData: undefined })}
        />
      );
      return <div>{content}</div>;
    }

    let beforeForm = [];
    if (this.state.customerToMove) {
      const plc = [];
      plc.push(<option value="">{t("select")}</option>);
      this.state.allPlaces.forEach(place => {
        if (!place.customerId) {
          plc.push(
            <option value={place.placeId}>
              {place.placeCode}: {place.placeLength}x{place.placeWidth}x
              {place.placeDepth} m
            </option>
          );
        }
      });
      beforeForm.push(
        <center>
          {t("moveTo")}{" "}
          <select
            onChange={e => this.setState({ moveTargetPlaceId: e.target.value })}
          >
            {plc}
          </select>
          <BkModifyButton
            disabled={!this.state.moveTargetPlaceId}
            buttonTitle={t("select-button")}
            callback={this.moveCustomer}
          />
          <BkModifyButton
            buttonTitle={t("cancel-button")}
            callback={() =>
              this.setState({
                customerToMove: undefined,
                moveTargetPlaceId: undefined
              })
            }
          />
        </center>
      );
    }
    const PAYMENT_LINK_BUTTON = {
      supportsMulti: true,
      callback: (d) => this.sendPaymentLink(d, "SEND_PAYMENT_LINK"),
      title: t("send-payment-button"),
      requiresCustomer: true,
      disabledCallback: this.buttonDisabledCallback,
      requiresEmailOrMobile: true,
      requiresEmailTemplate: "PAYMENT_LINK",
      requiresSmsTemplate: "PAYMENT_LINK_SMS",
      enabledForOldSeason: true,
      whenStatus: [
        "IN_CART",
        "TO_BE_INVOICED",
        "INVOICED",
        "FREE",
        "EXPIRED",
        "CANCELLED"
      ]
    };
    const PAYMENT_REMINDER_BUTTON = {
      supportsMulti: true,
      callback: (d) => this.sendPaymentLink(d, "SEND_PAYMENT_REMINDER"),
      title: t("send-reminder-button"),
      requiresCustomer: true,
      disabledCallback: this.buttonDisabledCallback,
      requiresEmailOrMobile: true,
      requiresEmailTemplate: "PAYMENT_REMINDER_LINK",
      requiresSmsTemplate: "PAYMENT_REMINDER_LINK_SMS",
      enabledForOldSeason: true,
      whenStatus: [
        "PAYMENT_LINK_SENT",
        "PAYMENT_REMINDER_SENT"
      ]
    };
    const CANCEL_PAYMENT_LINK_BUTTON = {
      supportsMulti: false,
      callback: this.setToReserved,
      title: t("cancel-payment-link-button"),
      requiresCustomer: true,
      disabledCallback: this.buttonDisabledCallback,
      requiresEmailOrMobile: false,
      enabledForOldSeason: true,
      whenStatus: [
        "PAYMENT_LINK_SENT",
        "PAYMENT_REMINDER_SENT",
        "INVOICED"
      ]
    };
    const SET_PAID_BUTTON = {
      supportsMulti: true,
      callback: this.setPaid,
      title: t("mark-paid-button"),
      requiresCustomer: true,
      disabledCallback: this.buttonDisabledCallback,
      enabledForOldSeason: true,
      whenStatus: [
        "TO_BE_INVOICED",
        "INVOICED",
        "PAYMENT_LINK_SENT",
        "FREE",
        "EXPIRED",
        "CANCELLED",
        "IN_CART"
      ]
    };
    const SET_FREE_BUTTON = {
      supportsMulti: true,
      callback: this.setFree,
      title: t("free-button"),
      requiresCustomer: true,
      disabledCallback: this.buttonDisabledCallback,
      enabledForOldSeason: true,
      whenStatus: [
        "IN_CART",
        "TO_BE_INVOICED",
        "INVOICED",
        "PAYMENT_LINK_SENT",
        "FREE",
        "EXPIRED",
        "CANCELLED",
        "PAID",
        "MARKED_PAID",
        "INVOICE_PAID",
        "SEND_PAYMENT_LINK",
        "SEASON_OCCUPIED" // should never happen, but once there was a bug...
      ]
    };
    const MOVE_BUTTON = {
      supportsMulti: false,
      callback: this.openMoveCustomer,
      title: t("move-customer-button"),
      requiresCustomer: true,
      disabledCallback: this.buttonDisabledCallback,
      whenStatus: [
        "IN_CART",
        "TO_BE_INVOICED",
        "INVOICED",
        "PAYMENT_LINK_SENT",
        "FREE",
        "EXPIRED",
        "CANCELLED",
        "PAID",
        "MARKED_PAID"
      ]
    };
    const SEND_INVOICE_BUTTON = {
      supportsMulti: true,
      callback: this.setToBeInvoiced,
      title: t("send-invoice-button"),
      requiresCustomer: true,
      requiresInvoicing: true,
      disabledCallback: this.buttonDisabledCallback,
      enabledForOldSeason: true,
      whenStatus: [
        "IN_CART",
        "PAYMENT_LINK_SENT",
        "TO_BE_INVOICED",
        "FREE",
        "EXPIRED",
        "CANCELLED"
      ]
    };
    const PRINT_INVOICE_BUTTON = {
      supportsMulti: false,
      callback: this.downloadInvoice,
      title: t("download-invoice-button"),
      requiresCustomer: true,
      requiresInvoicing: true,
      disabledCallback: this.buttonDisabledCallback,
      enabledForOldSeason: true,
      whenStatus: [
        "IN_CART",
        "PAYMENT_LINK_SENT",
        "INVOICED",
        "EXPIRED",
        "FREE",
        "CANCELLED"
      ]
    };
    const RESEND_CONFIRMATION_BUTTON = {
      supportsMulti: false,
      callback: this.resendConfirmation,
      title: t("resend-confirmation-button"),
      requiresCustomer: true,
      disabledCallback: this.buttonDisabledCallback,
      enabledForOldSeason: false,
      whenStatus: ["INVOICE_PAID", "PAID", "MARKED_PAID"]
    };
    const NEW_BUTTON = {
      title: t("new-button"),
      callback: () => this.openCallback({ placeId: 0 })
    }
    const READ_BS = {
      title: t("read-bs-button"),
      fileUpload: true,
      fileExtensions: "csv",
      callback: (d) => this.openSendBs(d)
    }
    const ACTIVATE_READ_BS = {
      title: t("read-bs-button"),
      callback: (d) => this.setState({ uploadBs: true })
    }
    const ACTIVATE_EXCEL_IMPORT = {
      title: t("customer-import-button"),
      callback: (d) => this.sendExport(d)
    }
    let listButtonArray = [
      this.state.uploadBs ? READ_BS : ACTIVATE_READ_BS,
      PAYMENT_LINK_BUTTON,
      PAYMENT_REMINDER_BUTTON,
      SEND_INVOICE_BUTTON,
      SET_PAID_BUTTON,
      SET_FREE_BUTTON,
      NEW_BUTTON,
      ACTIVATE_EXCEL_IMPORT
    ];
    let formButtonArray = [];
    if (!this.state.placeEditMode) {
      formButtonArray = [
        PAYMENT_LINK_BUTTON,
        PAYMENT_REMINDER_BUTTON,
        SEND_INVOICE_BUTTON,
        SET_PAID_BUTTON,
        RESEND_CONFIRMATION_BUTTON,
        PRINT_INVOICE_BUTTON
      ];
    } else {
      formButtonArray = [SET_FREE_BUTTON, CANCEL_PAYMENT_LINK_BUTTON, MOVE_BUTTON];
    }

    const content = [];

    if (this.state.data) {
      // opened place details
      const buttons = getButtons(formButtonArray, [this.state.data]);

      content.push(beforeForm);

      content.push(
        <SeasonPlaceEdit
          availableTemplates={this.state.availableTemplates}
          errorMessage={this.state.errorMessage}
          okMessage={this.state.okMessage}
          clearError={()=>this.setState({errorMessage:undefined})}
          clearOk={()=>this.setState({okMessage:undefined})}
          busy={this.state.busy}
          user={this.props.user}
          subareaId={this.props.subareaId}
          defaults={this.props.defaults}
          place={this.state.data}
          editMode={this.state.placeEditMode}
          placeDetails={this.state.placeDetails}
          location={this.props.location}
          closeCallback={() => {
            if (this.props.closeCallback) {
              this.props.closeCallback();
            }
            this.setState({
              data: undefined,
              placeEditMode: false,
              customerToMove: undefined
            });
            this.loadPlaces(this.state.first);
          }}
          editModeCallback={edit => this.setState({ placeEditMode: edit })}
          savePlaceAndProducts={data => {
            if (this.props.savePlaceAndProducts) {
              this.props.savePlaceAndProducts(data);
              this.setState({ placeEditMode: false });
            } else {
              const reqId = Math.random() * 100000;
              data.areaId = this.state.areaId;
              data.requestId = reqId;
              this.setState({ saveOngoing: reqId, busy: true });
              return reservationService
                .storeProductDetails(this.props.location.language, data)
                .then(ret => {
                  this.setState({
                    saveOngoing: false,
                    placeEditMode: !!ret.errorMessage,
                    errorMessage: ret.errorMessage,
                    okMessage: ret.okMessage,
                    placeDetails: ret.errorMessage
                      ? this.state.placeDetails
                      : ret,
                    busy: false,
                    data: ret.errorMessage ? this.state.data : ret
                  });
                  return this.loadPlaces(this.state.first);
                });
            }
          }}
          customerEditable={true}
          buttons={buttons}
        />
      );
    } else {
      if (error) {
        content.push(error);
      }
      content.push(<span className="search"><label onClick={() => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.loadPlaces(0), 500);
        this.setState({
          filter: this.state.filter.set("invoiceable", !this.state.filter.get("invoiceable"))
        });
      }}><input type="checkbox" checked={this.state.filter.get("invoiceable")}/>{t("filter.invoiceable")}</label></span>)
      content.push(<span className="search"><label onClick={() => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.loadPlaces(0), 500);
        this.setState({
          filter: this.state.filter.set("newCustomer", !this.state.filter.get("newCustomer"))
        });
      }}><input type="checkbox" checked={this.state.filter.get("newCustomer")}/>{t("filter.newCustomer")}</label></span>)
      const pfs = this.state.filter.get("placeForSale");
      content.push(
        <span className="search">
          <select
            onChange={e => {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => this.loadPlaces(0), 500);
              this.setState({
                filter: this.state.filter.set("placeForSale", e.target.value)
              });
            }}
          >
            <option value="ALL" selected={pfs === "ALL"}>
              {t("filter.allSales")}
            </option>
            <option value="true" selected={pfs === "true"}>
              {t("placeForSale")}
            </option>
            <option value="false" selected={pfs === "false"}>
              {t("placeNotForSale")}
            </option>
          </select>
        </span>
      );
      //<input type="checkbox" checked={this.state.filter.fetch("placeForSale")}
      content.push(
        <span className="search">
          <EnumSelect
            emptyLabel={t("filter.allPaymentStatuses")}
            location={this.props.location}
            enumType="ReservationStatus"
            filter={v => !["REPLACED", "TO_BE_INVOICED", "RETURNED", "SEND_PAYMENT_LINK", "SEND_PAYMENT_REMINDER", "CANCELLED"].includes(v)}
            selected={this.state.filter.get("status")}
            cb={v => {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => this.loadPlaces(0), 500);
              this.setState({
                filter: this.state.filter.set(
                  "status",
                  v == t("filter.allPaymentStatuses") ? "" : v
                )
              });
            }}
          />
        </span>
      );
      content.push(
        <span className="smallSearch">
          {t("size")}:
          <input
              placeholder={t("length")}
              onChange={e => {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => this.loadPlaces(0), 500);
                console.log("Timeout set, length value: " + e.target.value);
                this.setState({
                  filter: this.state.filter.set(
                    "length",
                    e.target.value
                  )
                });
              }}
              value={this.state.filter.get('length')}
          />
          <input
            placeholder={t("width")}
            onChange={e => {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => this.loadPlaces(0), 500);
              console.log("Timeout set, width value: " + e.target.value);
              this.setState({
                filter: this.state.filter.set(
                  "width",
                  e.target.value
                )
              });
            }}
            value={this.state.filter.get('width')}
          />
          <input
            placeholder={t("depth")}
            onChange={e => {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => this.loadPlaces(0), 500);
              console.log("Timeout set, depth value: " + e.target.value);
              this.setState({
                filter: this.state.filter.set(
                  "depth",
                  e.target.value
                )
              });
            }}
            value={this.state.filter.get('depth')}
          />
        </span>
      );
      content.push(
        <span className="search">
          {t("search")}:
          <input
            placeholder={t("search")}
            onChange={e => {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => this.loadPlaces(0), 500);
              console.log("Timeout set, search value: " + e.target.value);
              this.setState({ search: e.target.value });
            }}
            value={this.state.search}
          />
        </span>
      );
      content.push(
        <span className="search" style={{ width: "250px" }}>
          <AreaSelect
            emptyLabel={t("filter.allHarbours")}
            areas={this.props.areas}
            selectedSellerId={this.state.sellerId}
            selectedAreaId={this.state.areaId}
            cb={v => {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => this.loadPlaces(0), 500);
              this.setState({
                // eslint-disable-next-line eqeqeq
                areaId: v == t("filter.allHarbours") ? undefined : v
              });
            }}
          />
        </span>
      );
      content.push(
        <span className="search" style={{ width: "50px" }}>
          <a href={exportUrl}>{t("download-customers-file")}</a>
        </span>
      );
      content.push(
        this.state.loadingPlaces ? (
          <CircleLoader />
        ) : (
          <SeasonPlaceList
            selectCallback={this.selectCallback}
            openCallback={this.openCallback}
            t={t}
            l={this.props.location}
            places={this.state.places}
            selected={this.state.selectedItems}
          />
        )
      );
      content.push(
        <div className="searchListControls sticky-bottom">
          {this.getPreviousNext(t)} <br />
          {getButtons(listButtonArray, this.state.selectedItems)}
        </div>
      );
    }
    if (this.props.openedData) {
      return content;
    }
    return (
      <React.Fragment>
        <LoginRequired location={location} {...this.props.loginProps} />
        <BookkaaHeader
          location={location}
          {...this.props.headerProps}
          disableProfile={true}
        />
        <LoginForm location={location} {...this.props.loginProps} />
        <Profile location={location} {...this.props.profileProps}>
          {content}
        </Profile>
      </React.Fragment>
    );
  }

  getPreviousNext(t) {
    const prevCb = () =>
      this.loadPlaces(
        this.state.first < this.state.pageSize
          ? 0
          : this.state.first - this.state.pageSize
      );
    const nextCb = () => this.loadPlaces(this.state.last + 1);
    const pageCb = p => this.loadPlaces(this.state.pageSize * p);
    // eslint-disable-next-line eqeqeq
    return (
      <React.Fragment>
        <center>
          <span>
            {t("show")}
            <select
              onChange={e => {
                this.loadPlaces(0, e.target.value);
              }}
            >
              {[20, 30, 50, 100, 200, 500].map(n => (
                // eslint-disable-next-line eqeqeq
                <option value={n} selected={this.state.pageSize == n}>
                  {n}
                </option>
              ))}
            </select>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <SearchListControls
            pageSize={this.state.pageSize}
            first={this.state.first}
            last={this.state.last}
            total={this.state.totalCount}
            location={this.props.location}
            prevCb={prevCb}
            nextCb={nextCb}
            pageCb={pageCb}
          />
        </center>
      </React.Fragment>
    );
  }

  acceptUpdate() {
    const l = this.props.location;
    const data = this.state.importData;
    return genericPut(
      l.language,
      `seasonAdmin/places/${l.area}/accept`,
      data,
      "booking-web"
    ).then(r =>
      this.setState({
        result: r,
        errorMessage: r.errorMessage,
        okMessage: r.okMessage,
        importData: undefined
      })
    );
  }
}

const SeasonPlaceEdit = props => {
  const hasAddonMpTemplate = props.availableTemplates && (
    props.availableTemplates.indexOf("PAYMENT_LINK_ADDITIONAL") != -1 ||
    props.availableTemplates.indexOf("PAYMENT_LINK_ADDITIONAL_SMS") != -1);
  const hasCustomer = props.place && props.place.holder;
  const paid = props.place && props.place.paid;
  //const invoiced = props.place && props.place.holder && props.place.holder.status === "INVOICED"
  return (
    <div className="bk_seasonAdmin">
      <OkErrorMessage okMessage={props.okMessage} errorMessage={props.errorMessage} clearOk={props.clearOk} clearError={props.clearError} />
      <PlaceDetailsRenderer
        errorMessage={props.errorMessage}
        user={props.user}
        location={props.location}
        defaults={props.defaults}
        season={true}
        saveOngoing={props.saveOngoing || props.busy}
        editMode={props.editMode}
        editModeCallback={props.editModeCallback}
        simple={!props.placeDetails || !props.placeDetails.editAccess}
        buttons={props.buttons}
        customerEditable={props.customerEditable}
        placeDetails={props.placeDetails}
        openedPlaceDetails={props.place}
        closeCallback={props.closeCallback}
        subareaId={props.subareaId}
        savePlaceAndProducts={props.savePlaceAndProducts}
        addonPaymentRequest={hasAddonMpTemplate && hasCustomer && paid}
      />
    </div>
  );
};

const SeasonPlaceList = props => {
  const { selectCallback, openCallback, t, places, selected, l } = props;
  let odd = false;

  const rows = [];
  const allSelected = selected && selected.length === places.length;
  rows.push(
    <tr>
      <th>
        {t("selected") + " " + (selected ? selected.length : 0)}
        <input
          type="checkbox"
          checked={allSelected}
          onClick={() =>
            allSelected ? selectCallback() : selectCallback(places, true)
          }
        />
      </th>
      <th>{t("area")}</th>
      <th>{t("pier")}</th>
      <th>{t("placeCode")}</th>
      <th>{t("placeType")}</th>
      <th>{t("report.firstName")}</th>
      <th>{t("report.lastName")}</th>
      <th>{t("report.email")}</th>
      <th>{t("report.phone")}</th>
      <th>{t("placeForSale")}</th>
      <th>{t("reservationStatus")}</th>
      <th />
    </tr>
  );

  if (places && Array.isArray(places)) {
    places.forEach(p => {
      let isSelected = false;
      selected.forEach(
        i => (isSelected = isSelected || i.placeId === p.placeId)
      );
      const cn = (odd ? "td-odd" : "td-even") + (isSelected ? " selected" : "");
      odd = !odd;
      const tdClass = p.oldSeason ? "OLD_SEASON" : p.reservationStatus;

      const resCn = cn + " td-" + tdClass;
      rows.push(
        <tr
          onClick={e =>
            selectCallback(p, e.ctrlKey || e.target.type == "checkbox")
          }
        >
          <td className={cn}>
            <input type="checkbox" checked={isSelected}></input>
          </td>
          <td className={cn}>{p.areaName}</td>
          <td className={cn}>{p.pier}</td>
          <td className={cn}>{p.placeCode}</td>
          <td className={cn}>{p.placeTypeStr}</td>
          <td className={cn}>{p.firstName}</td>
          <td className={cn}>{p.lastName}</td>
          <td className={cn}>{p.email}</td>
          <td className={cn}>{p.mobile}</td>
          <td className={cn}>{p.placeForSale}</td>
          <td className={resCn}>{p.reservationStatusStr}</td>
          <td className={cn}>
            <a href={`/admin/${l.language}/${p.areaId}/customers/${p.placeId}`} onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault();
                openCallback(p);
              }
            }}>{t("open-place-link")}</a>
          </td>
        </tr>
      );
    });
  }

  return <table className="bk-list">{rows}</table>;
};

export default SeasonCustomers;
