import React from "react";

const BkFooter = props => {
  return (
    <React.Fragment>
      <div style={{ clear: "both", width: "100%", height: "5px" }} />
      <div className="bk-footer">
        <div className="container">
          <div className="flex_column av_one_half  first el_before_av_one_half bk_leftcolumn">
            <h3 className="widgettitle">satamapaikka.com ServiceDesk</h3>
            <div>
              <p>Tel. +358 20 7344 728</p>
              <p>servicedesk@satamapaikka.com</p>
            </div>
            <span className="seperator extralight-border" />
          </div>
          <div className="flex_column av_one_half  el_after_av_one_half  el_before_av_one_half bk_rightcolumn">
            <center>
              <div>
                <p>&nbsp;</p>
                <p>
                  <a
                    target="_blank"
                    href="http://www.satamapaikka.com"
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    <img
                      src="/static-web/logos/satamapaikka_long.png"
                      alt="Ylivoimaista venepaikkojen hallintaa"
                    />
                  </a>
                </p>
              </div>
              <span className="seperator extralight-border" />
            </center>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default BkFooter;
